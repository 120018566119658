<template>


  <IndexPage />


</template>

<script>

import IndexPage from './pages/index.vue'

export default {
  name: 'App',
  components: {
    IndexPage
  }
}
</script>

<style>

</style>
