<template>
  <footer id="contact">
    <div class="wide">
      <h3>Digital GxP</h3>
      <div class="grid-three">
        <div class="col1 inner">
          <p class="tagline">
            Paperless Digital SOP, Protocol, Batch Records, Forms, Checksheets,
            Logbooks
          </p>
          <p class="about">
            With a combined industry experience of over 3 decades in SaaS
            platforms & GxP industry, our aim is to digitalize and drive towards
            pharma industry 4.0 starting with paperless systems
          </p>
          <p class="copywright">
            Copyright © 2022 Digital GxP. All rights reserved.
          </p>
        </div>
        <div class="col2 inner">
          <h4>Contact Us</h4>
          <ul>
            <li>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_82_49)">
                  <path
                    d="M12.6875 1.75H1.31251C0.588747 1.75 0 2.33875 0 3.06251V10.9375C0 11.6613 0.588747 12.25 1.31251 12.25H12.6875C13.4113 12.25 14 11.6613 14 10.9375V3.06251C14 2.33875 13.4113 1.75 12.6875 1.75ZM12.6875 2.625C12.7469 2.625 12.8035 2.63733 12.8552 2.65887L7 7.73359L1.14479 2.65887C1.19649 2.63736 1.25304 2.625 1.31248 2.625H12.6875ZM12.6875 11.375H1.31251C1.07112 11.375 0.874996 11.1789 0.874996 10.9375V3.58331L6.71331 8.64319C6.79578 8.71452 6.89789 8.75 7 8.75C7.10211 8.75 7.20422 8.71455 7.28669 8.64319L13.125 3.58331V10.9375C13.125 11.1789 12.9289 11.375 12.6875 11.375Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_82_49">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <a href="mailto:info@digitalgxp.com"> info@digitalgxp.com </a>
            </li>

            <li>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.86445 4.36355C4.74548 3.43546 5.05685 3.17516 5.43229 3.05402C5.69211 2.98593 5.96461 2.98215 6.22622 3.043C6.57062 3.14312 6.66173 3.21921 7.79106 4.34453C8.78322 5.33269 8.88234 5.43982 8.97745 5.63304C9.15932 5.97222 9.18773 6.3729 9.05554 6.73434C8.95542 7.00966 8.81326 7.19187 8.21155 7.79558L7.81909 8.18905C7.71602 8.29379 7.6918 8.45284 7.75902 8.58351C8.63098 10.0711 9.86807 11.3116 11.3532 12.1877C11.5242 12.2793 11.7347 12.2497 11.8739 12.1147L12.2513 11.7432C12.4846 11.5041 12.7316 11.2788 12.9912 11.0684C13.3988 10.8181 13.9067 10.7958 14.3347 11.0094C14.544 11.1095 14.6131 11.1715 15.6363 12.1927C16.6915 13.245 16.7215 13.278 16.8377 13.5193C17.0562 13.9187 17.054 14.4025 16.8317 14.7998C16.7185 15.0241 16.6495 15.1052 16.0558 15.7119C15.6973 16.0783 15.3599 16.4127 15.3059 16.4618C14.8161 16.8673 14.1877 17.0662 13.5538 17.0164C12.394 16.9107 11.2714 16.5522 10.265 15.9662C8.03548 14.785 6.13752 13.0639 4.74448 10.9603C4.44107 10.5201 4.17132 10.0575 3.93753 9.57668C3.31038 8.50178 2.98651 7.27691 3.00043 6.03251C3.04831 5.38151 3.36051 4.77846 3.86445 4.36355Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <a href="tel:+12034241646">+1 203 424 1646</a>
            </li>

            <li>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_82_30)">
                  <path
                    d="M6.99998 0C4.12126 0 1.7793 2.3429 1.7793 5.22276C1.7793 9.51355 6.48033 13.7031 6.68052 13.8794C6.77178 13.9598 6.88586 14.0001 6.99998 14.0001C7.1141 14.0001 7.22817 13.9598 7.31948 13.8794C7.51958 13.7032 12.2207 9.51359 12.2207 5.22276C12.2207 2.3429 9.8787 0 6.99998 0ZM6.99998 12.854C5.96063 11.8469 2.7461 8.46883 2.7461 5.22276C2.7461 2.87603 4.65439 0.966802 6.99998 0.966802C9.34557 0.966802 11.2539 2.87603 11.2539 5.22276C11.2539 8.46878 8.03932 11.8468 6.99998 12.854Z"
                    fill="white"
                  />
                  <path
                    d="M6.99997 2.78955C5.66723 2.78955 4.58301 3.88099 4.58301 5.22254C4.58301 6.56413 5.66723 7.65552 6.99997 7.65552C8.3327 7.65552 9.41693 6.56413 9.41693 5.22254C9.41693 3.88099 8.3327 2.78955 6.99997 2.78955ZM6.99997 6.68872C6.20032 6.68872 5.54976 6.031 5.54976 5.22254C5.54976 4.41412 6.20032 3.75635 6.99997 3.75635C7.79962 3.75635 8.45017 4.41407 8.45017 5.22254C8.45017 6.031 7.79962 6.68872 6.99997 6.68872Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_82_30">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <a>
                65 Nostrand Road, Hillsborough,<br />
                NJ 07034, USA
              </a>
            </li>
          </ul>
        </div>
        <div class="col3 inner">
          <form action="" class="contact">
            <div class="col c1">
              <label for="fname">Full Name</label>
              <input
                type="text"
                id="fname"
                name="name"
                placeholder="Your name.."
                v-model="formName"
              />

              <label for="femail">Email</label>
              <input
                type="email"
                id="femail"
                name="email"
                placeholder="Your email.."
                v-model="formEmail"
              />

              <label for="phone">Phone</label>
              <input
                type="tel"
                id="fphone"
                name="phone"
                placeholder="Your phone.."
                v-model="formPhone"
              />
            </div>
            <div class="col c2">
              <label for="subject">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Your message.."
                v-model="formMessage"
              ></textarea>

              <input type="button" value="SEND" v-on:click="sendContact" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";

export default {
  name: "LandingFooter",
  data() {
    return {
      formName: null,
      formEmail: null,
      formPhone: null,
      formMessage: null,
    };
  },
  methods: {
    async sendContact() {
      let app = this;

      const api_url = "https://digitalgxp.com/email/contact/";

      var reg =
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      let name = app.formName;
      let email = app.formEmail;
      let phone = app.formPhone;
      let message = app.formMessage;

      if (email == null || email == "") {
        // window.alert("Please Enter an email address!");
      } else {
        email = email.trim();
      }

      if (!reg.test(email)) {
        window.alert("Please Enter a valid email address!");
      } else if (!name) {
        window.alert("Please enter your Full Name.");
      } else if (!phone) {
        window.alert("Please enter your Phone Number.");
      } else if (!message) {
        window.alert("Please enter a Message.");
      } else {
        let res = await axios
          .post(api_url, {
            name,
            email,
            phone,
            message,
          })
          .then(function () {
            // console.log(response);
            // app.showLoginModal = true;
            // app.showRegisterModal = false;
            // app.$router.push("/login");
            //   app.emailForDemo = "";
            // document.getElementById("fname").value = '';
            // document.getElementById("femail").value = '';
            // document.getElementById("fphone").value = '';
            // document.getElementById("fmessage").value = '';
            window.alert(
              "Your message has been sent. We will contact you shortly. \n\n-Team Digital GxP"
            );
          })
          .catch(function (error) {
            console.log(error);
          });

        console.log(res);
      }
    },
  },
};
</script>

<style>
footer p,
footer a {
  font-size: 0.8rem;
}

footer h3,
footer h4,
footer p.about,
footer a {
  color: #fff;
}

footer p {
  color: var(--light-grey);
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
}

ul {
  padding-inline: 0;
  margin-block: 0;
  margin-inline: 0;
}

footer li svg {
  width: 1.1rem;
  height: 1.1rem;
}

footer input[type="button"],
footer input[type="submit"] {
  margin-top: 1rem;
}

footer {
  background-color: var(--dark-bg);
  padding: 2rem 0 4rem 0;
}

footer .grid-three {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr)); */
  grid-template-columns: 1fr;
  gap: 4rem;
}

footer .inner,
footer ul {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

footer li {
  display: flex;
  column-gap: 1em;
}

form.contact {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  gap: 1rem;
}

form .col {
  display: flex;
  flex-direction: column;
}

/* 
    @media only screen and (min-width: 589px) {
        footer form .c1 {
            padding-right: 2rem;
        }
    } */

@media only screen and (min-width: 1025px) {
  footer .grid-three {
    grid-template-columns: 1fr 1fr 2fr;
  }
}
</style>
